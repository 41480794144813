/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();
                var content = 'html,body';
                if (viewportWidth < 768) {
                    content = '.content-wrap';
                }

                if ('undefined' != typeof FLBuilderLayoutConfig) {
                    FLBuilderLayoutConfig.anchorLinkAnimations.duration = 1000;
                    FLBuilderLayoutConfig.anchorLinkAnimations.easing = 'swing';
                    FLBuilderLayoutConfig.anchorLinkAnimations.offset = 0;
                }

                /*var i18n = {
                 previousMonth : 'Vormonat',
                 nextMonth     : 'Nächster Monat',
                 months        : ['Januar','Februar', 'März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
                 weekdays      : ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
                 weekdaysShort : ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.']
                 };

                 var picker = new Pikaday({
                 disableDayFn: function(date){
                 // Disable Sonntag
                 return date.getDay() === 0;
                 },
                 field: $('#desired_date')[0],
                 format: "D.M.YY", //adjust to your liking
                 minDate: moment().toDate(),
                 maxDate: moment().add(14,'days').toDate(),
                 i18n: i18n,
                 firstDay:1,
                 onSelect: function() {
                 console.log(this.getMoment().format('L'));
                 }
                 });*/

                $('#desired_date').dateDropper();

                $('#billing_phone_field').find('label').append('&nbsp;<abbr class="required" title="erforderlich">*</abbr>');
                $('#billing_phone').prop('required', true);

                $('#desired_date_field').find('label').append('&nbsp;<abbr class="required" title="erforderlich">*</abbr>');
                $('#desired_date').prop('required', true);

                // Smooth Scrolling Script by @chriscoyier
                $('a[href*="#"]:not([href="#"])').click(function () {
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);

                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            $(content).animate({
                                scrollTop: target.offset().top
                            }, 1000, "swing").promise().done(function () {
                                if ($(".off-canvas-wrap").hasClass("move-left")) {
                                    $(".off-canvas-wrap").toggleClass("move-left");
                                }
                            });
                            return false;
                        }
                    }
                });

                $("html, body").bind("scroll mousedown DOMMouseScroll mousewheel keyup", function () {
                    $(content).stop();
                });


                // Scroll-Top-Btn / Floaty-Bar fading function
                // Different scroll targets on mobile / desktop
                var viewportWidth = $(window).width();

                // responsive image ratio
                function responsve_js_image_ratio() {
                    var ratio = 0.578125;
                    var image_ratio_viewport_width = $(window).width();
                    var wrapper_height = (image_ratio_viewport_width * ratio);
                    var image_wrapper = $('.responsive-ratio .fl-row-content-wrap');
                    image_wrapper.css("height", wrapper_height);
                }

                if (viewportWidth < 768) {
                    $('.content-wrap').scroll(function () {
                        var viewportHeight = $(document).height();
                        var y = $(this).scrollTop();

                        if (y > viewportHeight / 2) {
                            $('.to-top').removeClass('hidden');
                            $('.floaty-wrap').removeClass('hidden');
                        } else {
                            $('.to-top').addClass('hidden');
                            $('.floaty-wrap').addClass('hidden');
                        }
                    });
                } else {
                    $(document).scroll(function () {
                        var viewportHeight = $(window).height();
                        var y = $(this).scrollTop();

                        if (y > viewportHeight / 2) {
                            $('.to-top').removeClass('hidden');
                            $('.floaty-wrap').removeClass('hidden');
                        } else {
                            $('.to-top').addClass('hidden');
                            $('.floaty-wrap').addClass('hidden');
                        }
                    });
                }

                // Mobile scripts
                if (Modernizr.touch) {
                    $('#desktop-nav li:has(ul)').doubleTapToGo();
                }

                // Toggle function for offcanvas navigation
                $("#nav-toggle, .exit-off-canvas").click(function () {
                    $(".off-canvas-wrap").toggleClass("move-left");
                });

                $(".right-off-canvas-menu li:not(.menu-item-has-children)").click(function () {
                    $(".off-canvas-wrap").toggleClass("move-left");
                });

                //Add Toggle Icons to Offcanvas for Dropdowns
                var parentItem = $('.right-off-canvas-menu .menu-item-has-children');
                $(parentItem).each(function () {
                    $(this).children('a').after('<div class="toggle"><span class="glyphicon glyphicon-chevron-right"></span></div>');
                });

                // Control the open/clos state of submenus depending on current or active menu-items
                $('.right-off-canvas-menu .current-menu-ancestor').addClass('open');
                $('.right-off-canvas-menu .current-menu-parent').addClass('open');
                $('.right-off-canvas-menu .menu-item-has-children.current-menu-item').addClass('open');

                // Prevents the Submenus from stayin' opened after page reloads like it does on phones
                $('.right-off-canvas-menu .menu-item-has-children > .toggle').on('click', function () {
                    var element = $(this).parent('li');
                    if (element.hasClass('open')) {
                        element.removeClass('open');
                    }
                    else {
                        element.addClass('open');
                    }
                });
                if (viewportWidth > 768) {
                    responsve_js_image_ratio();
                }

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                // responsive image ratio
                function responsve_js_image_ratio() {
                    var ratio = 0.578125;
                    var image_ratio_viewport_width = $(window).width();
                    var wrapper_height = (image_ratio_viewport_width * ratio);
                    var image_wrapper = $('.responsive-ratio .fl-row-content-wrap');
                    image_wrapper.css("height", wrapper_height);
                }

                var viewportWidth = $(window).width();
                // Only make the nav sticky on tablet and up
                if (viewportWidth > 768) {
                    // Define Vars for affix calcs
                    var nav = $('#desktop-nav');
                    var navHeight = $(nav).height();
                    var offsetAffix = $(nav).offset();
                    offsetAffix = {top: 757, left: 0};
                    // Makes the nav sticky
                    $(nav).affix({
                        offset: {
                            top: offsetAffix.top // You may have to add some extra px for borders etc.
                        }
                    });
                    // Add padding to body to prevent lil' page-jump when nav is stickfied
                    $(nav).on('affix.bs.affix', function () {
                        $('body').css('padding-top', navHeight + 'px');
                    });
                    // Remove the x-tra padding after nav gets static again
                    $(nav).on('affixed-top.bs.affix', function () {
                        $('body').css('padding-top', '0');
                    });
                }

                // Recalculate offset for sticky element and position for mega dropdown centering on resize
                $(window).resize(function () {
                    var offsetAffix = $(nav).offset();
                    if (viewportWidth > 768) {
                        responsve_js_image_ratio();
                    }
                });
            }
        },
        // Home page
        'datenschutz': {
            init: function () {
                // JavaScript to be fired on the home page
                function replaceLinksAnchors() {
                    links = $('#desktop-nav ul li a');
                    links.each(function () {
                        $(this).attr("href", '/' + this.hash);
                    });
                }

                replaceLinksAnchors();
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'impressum': {
            init: function () {
                function replaceLinksAnchors() {
                    links = $('#desktop-nav ul li a');
                    links.each(function () {
                        $(this).attr("href", '/' + this.hash);
                    });
                }

                replaceLinksAnchors();
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
